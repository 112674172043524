<template>
  <b-form-select
    v-model="value.value"
    @change="setProjectManager(projectId, value && value.value)"
    :options="projectManagers"
  />
</template>
<script>
export default {
  props: {
    projectId: Number,
    projectManagers: Array,
    value: Object,
    setProjectManager: Function,
  },
  data: () => ({
    // selectedProjectManager: this.value && this.value.value,
  }),
};
</script>
