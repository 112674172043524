<template>
  <div>
    <h4>{{ $t('jobs.products-by-supplier') }}:</h4>
    <div
      v-for="{ id, jobApiId, name, products } of productsBySupplier"
      :key="id"
      class="ml-1"
    >
      <h5 class="mt-3 mb-2">{{ name }}</h5>

      <template v-if="products.length">
        <ul
          v-for="(product, index) of products"
          :key="index"
          class="bg-transparent list-unstyled"
        >
          <li>
            <span>{{ product.quantity }}&nbsp;&times;</span>
            <span class="ml-1">{{ product.name }}</span>
            <span
              v-if="!getCurrentProductOrder(jobApiId, id)"
              v-b-tooltip.hover
              :title="$t('products-order.missing-tracking-info')"
            >
              <BIconExclamationTriangle
                class="ml-2"
                style="font-size: 1.7rem"
                variant="danger"
              />
            </span>
          </li>
        </ul>
      </template>
      <template v-if="getCurrentProductOrder(jobApiId, id)">
        <div class="mb-1">
          <b-icon-box class="mr-2" />
          <a :href="getCurrentProductOrder(jobApiId, id)?.url" target="_blank">
            {{ $t('products-order.tracking-link') }}
          </a>
        </div>
        <div class="mb-2">
          {{ $t('products-order.shipping-to') }}:
          <b-badge variant="secondary">
            {{
              $t(
                `products-order.${
                  getCurrentProductOrder(jobApiId, id)?.shipTo
                }`,
              )
            }}
          </b-badge>
        </div>
      </template>

      <b-button
        v-if="canUpdateTrackingID"
        :variant="getCurrentProductOrder(jobApiId, id) ? 'primary' : 'danger'"
        class="mb-4"
        @click="$emit('toggleModal', { id, jobApiId, name, products })"
      >
        {{
          $t(
            `products-order.${
              getCurrentProductOrder(jobApiId, id) ? 'update' : 'add'
            }-tracking-info`,
          )
        }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BIconBox, BIconExclamationTriangle } from 'bootstrap-vue';
import { JobStatusEnum } from '@gid/models';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';

export default {
  components: {
    BIconBox,
    BIconExclamationTriangle,
  },
  props: {
    item: Object,
    getCurrentProductOrder: Function,
    toggleModal: Function,
  },
  computed: {
    canUpdateTrackingID() {
      return (
        this.item?.job?.states?.list.includes(
          `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`,
        ) ||
        this.item?.jobs.some((job) =>
          job.job.states?.includes(
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`,
          ),
        )
      );
    },

    productsBySupplier() {
      const bySupplier = {};

      this.item.products.forEach((product) => {
        const { supplier_sfid, supplier_name, job_api_id, id, name, quantity } =
          product;

        if (!bySupplier[supplier_sfid]) {
          bySupplier[supplier_sfid] = {
            id: supplier_sfid,
            jobApiId: job_api_id,
            name: supplier_name,
            products: [],
          };
        }

        bySupplier[supplier_sfid].products.push({
          id,
          name,
          quantity,
        });
      });

      return Object.values(bySupplier);
    },
  },
};
</script>
